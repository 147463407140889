<template>
    <div>
        <div class="commen-hader">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12">
                        <h2>MY ENQUIRES</h2>
                    </div>
                </div>
            </div>
        </div>
        <Loader :isLoading="isLoading" />
        <div class="profile-section">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8 col-md-12 col-sm-12">
                        <div class="profile-edit">
                            <h2><i class="fa fa-cogs"></i> ENQUIRY DETAILS</h2>

                            <div
                                class="upload-img"
                                style="border: none; margin-top: none;"
                                v-if="enquiredetails"
                            >
                                <div class="col-12">
                                    <table class="table table-sm" style="font-size: 0.85em">
                                        <tr>
                                            <td style="height: 100%; vertical-align: middle">
                                                Product Title
                                            </td>
                                            <td>
                                                <router-link
                                                    class="enquire"
                                                    :to="{
                                                        name: 'single-product',
                                                        params: {
                                                            id: enquiredetails.product_id,
                                                        },
                                                    }"
                                                    >{{ enquiredetails.title }}</router-link
                                                >
                                                <span
                                                    ><ImageItem :src="imgsrc + enquiredetails.image"
                                                /></span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Subject</td>
                                            <td>
                                                {{ enquiredetails.subject }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Price</td>
                                            <td>{{ enquiredetails.price }}</td>
                                        </tr>
                                        <tr>
                                            <td>Quantity</td>
                                            <td>{{ enquiredetails.quenty }}</td>
                                        </tr>
                                        <tr>
                                            <td>Message</td>
                                            <td>
                                                {{ enquiredetails.message }}
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="col-12 main-message-module">
                                    <h3
                                        class="ml-0 p-2"
                                        style="margin: 0; border-bottom: 1px solid #ccc;"
                                    >
                                        Message Exchanged
                                    </h3>
                                    <div class="messages-container">
                                        <p
                                            v-for="(message, index) in messages"
                                            :key="index"
                                            :class="
                                                message.sender_id == user.id ? 'send' : 'recevier'
                                            "
                                        >
                                            <span class="small font-weight-bold"
                                                >{{ message.user.name }} -
                                            </span>
                                            {{ message.message }}
                                        </p>
                                    </div>
                                    <div class="form-group my-2">
                                        <textarea
                                            placeholder="Type your message..."
                                            v-model="newMessage"
                                            class="mt-2 form-control message-textarea"
                                        ></textarea>
                                        <input
                                            v-if="enquiredetails.user_id !== user.id"
                                            v-model="enquiredetails.user_id"
                                            type="hidden"
                                            name="receiver_id"
                                            id="receiver_id"
                                            class="form-control"
                                        />
                                        <input
                                            v-else
                                            v-model="enquiredetails.auth_id"
                                            type="hidden"
                                            name="receiver_id"
                                            id="receiver_id"
                                            class="form-control"
                                        />
                                        <input
                                            v-model="enquiredetails.ticket_id"
                                            type="hidden"
                                            name="ticket_id"
                                            id="ticket_id"
                                            class="form-control"
                                        />
                                    </div>
                                    <div class="form-group text-right m-0 mb-2">
                                        <button
                                            class="primary-button send-message-btn"
                                            @click="sendMessage"
                                        >
                                            Send Message
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12"></div>
                            <div class="text-center py-1">
                                <a
                                    v-if="!isMaterialTester && !isSeller"
                                    href="javascript:void(0)"
                                    @click="findMaterialTesters()"
                                    class="primary-button primary-button-alt w-50 small my-0"
                                >
                                    Find Material Testers for this product
                                </a>
                                <!-- Rating can be created for either seller or buyer -->
                                <RatingButton
                                    v-if="enquiredetails"
                                    :forUserId="forUserId"
                                    :title="isSeller ? 'Rate The Buyer' : 'Rate The Seller'"
                                    :allowForOtherUsers="true"
                                    @ratingChange="handleRatingChange"
                                />
                                <!-- Show ratings only for the product surplus creator -->
                                <Ratings
                                    :title="isSeller ? 'Buyer Ratings' : 'Seller Ratings'"
                                    :hideRatingButton="true"
                                    :forUserId="forUserId"
                                    :materialTester="ratingUserDetails"
                                />
                            </div>
                        </div>
                    </div>
                    <LeftBar></LeftBar>
                </div>
            </div>
        </div>
    </div>
</template>
<script src="https://ajax.googleapis.com/ajax/libs/jquery/2.1.1/jquery.min.js"></script>
<script>
import axios from "axios"
import VueChatScroll from "vue-chat-scroll"
import LeftBar from "../components/leftbar.vue"
import RatingButton from "./Rating/RatingButton.vue"
import scrollTop from "./utils/scrollTop"
import ImageItem from "./utils/ImageItem"
import request from "../apis/request"
import { mapState, mapGetters } from "vuex"
import Ratings from "./Rating/Ratings.vue"
import Loader from "./Loader.vue"

//axios.defaults.withCredentials = true;

export default {
    name: "dashboard",
    components: {
        VueChatScroll,
        LeftBar,
        RatingButton,
        Ratings,
        Loader,
        ImageItem,
    },

    data() {
        return {
            plans: "",
            isLoading: false,
            product: "",
            enquire: "",
            ress: {},
            //email: "",
            //mobile: "",
            tagline: "",
            yourself: "",
            expertize: "",
            mobile1: "",
            website: "",
            old: "",
            password: "",
            password_confirmation: "",
            image: "",
            certificates: "",
            i: 0,
            enquiredetails: null,
            enquire_count: "",
            enquire_receiver_id: "",
            ticket_id: "",
            jobs_count: "",

            id: this.$route.params.id,

            messages: [],
            newMessage: "",
            receiver_id: "",
            sender_id: "",

            action: "/api/buyerupdate",

            //   imgsrc: "http://localhost/backend/public/admin_profile/",
            // imgsrc: "http://103.212.120.205/~dev/public/admin_profile/",

            imgsrc: axios.defaults.uploadURL,
            ratingUserDetails: null,
        }
    },

    methods: {
        handleRatingChange() {
            window.location.reload()
        },

        async fetchUserDetails(userId) {
            console.log("fetching user details")
            const res = await request.get("/user-details/" + userId)
            const data = res.data
            console.log("result of fetching user", data)
            this.ratingUserDetails = data.result
        },
        async findMaterialTesters() {
            try {
                const res = await request.get("/material-testers/check-material-tester-allowed")
                const allowed = res.data.allowed
                if (allowed) {
                    const routerObj = {
                        name: "hire_tester",
                        params: { id: this.enquiredetails.ticket_id },
                    }
                    return this.$router.push(routerObj)
                }
            } catch (err) {
                const message = err.response && err.response.data && err.response.data.message
                this.$toasted.error(message || "Sorry, you are not allowed to access this feature!")
            }
        },
        sendMessage() {
            if (!this.newMessage) {
                this.$toasted.error("Please enter message before sending")
                return
            }
            this.messages.push({
                // user: this.user,
                message: this.newMessage,
            })

            this.receiver_id = $("#receiver_id").val()
            this.ticket_id = $("#ticket_id").val()

            const form = new FormData()
            form.append("message", this.newMessage)
            form.append("receiver_id", this.receiver_id)
            form.append("ticket_id", this.ticket_id)

            request.post("/messageSend", form).then(() => {
                this.newMessage = ""
                this.$toasted.success("Message sent successfully", {
                    keepOnHover: true,
                    iconPack: "fontawesome",
                    icon: "check",
                    theme: "toasted-primary",
                    // timerProgressBar: true,
                    duration: 3000,
                })
            })
        },
        fetchMessages() {
            // this.ticket_id = $("#ticket_id").val();
            // console.log('ticket',this.id);
            const form = new FormData()
            form.append("ticket_id", this.id)

            request.get("/messageShow/" + this.id).then((response) => {
                //   console.log('message');
                this.messages = response.data
            })
        },

        logout() {
            request.post("/logout").then(() => {
                localStorage.removeItem("isLogged")
                window.localStorage.removeItem("isLogged")
                window.localStorage.removeItem("user")
                this.isLogged = false
                this.$router.push({ name: "landing" })
            })
        },

        previewFiles(event) {
            console.log(event)
            console.log(event.target.files[0])

            this.image = event.target.files[0]
            // console.log(this.image)
        },

        previewFiless(event) {
            console.log(event)
            //console.log(event.target.files[0]);

            this.certificates = event.target.files[0]
        },

        update: function(e) {
            e.preventDefault()

            this.ress = {}

            const form = new FormData()
            form.append("tagline", this.tagline)
            form.append("yourself", this.yourself)
            form.append("expertize", this.expertize)
            form.append("mobile1", this.mobile1)
            form.append("website", this.website)
            form.append("old", this.old)
            form.append("password", this.password)
            form.append("password_confirmation", this.password_confirmation)
            form.append("image", this.image)
            form.append("certificates", this.certificates)

            //console.log(this.image);

            request
                .post(this.action, form)
                .then((res) => {
                    window.localStorage.setItem("isLogged", true)
                    window.localStorage.setItem("user", JSON.stringify(res.data))
                    //console.log("localstorage", JSON.parse(window.localStorage.getItem('user')));
                    //  this.$router.push({name: 'dashboard'});
                    console.log(res)

                    this.$toasted.success("Profile Update in successfully", {
                        keepOnHover: true,
                        iconPack: "fontawesome",
                        icon: "check",
                        theme: "toasted-primary",
                        // timerProgressBar: true,
                        duration: 3000,
                    })

                    //   alert('Profile Update successfully!');
                })
                .catch((res) => {
                    //alert('err');
                    if (res.response.status === 422) {
                        this.ress = res.response.data.errors || {}
                    }
                    return res
                })
        },

        validateEmail: function() {
            const regMail = /^([_a-zA-Z0-9-]+)(\.[_a-zA-Z0-9-]+)*@([a-zA-Z0-9-]+\.)+([a-zA-Z]{2,3})$/
            let res = regMail.test(this.email)
            let msg
            if (res === false)
                msg = "<span class='warning' style='color: red;'>Email is not valid yet.</span>"
            else msg = "<span class='valid' style='color: green;'>Done!"
            document.getElementById("email-status").innerHTML = msg
            return res
        },

        validatemobile1: function() {
            let res = true
            let digitCount = 0
            for (let i = 0; i < this.mobile1.length; i++) {
                if (!(this.mobile1[i] >= "0" && this.mobile1[i] <= "9")) {
                    res = false
                } else {
                    digitCount++
                }
            }
            let msg
            if (res == false)
                msg = "<span class='warning' style='color: red;'>Oops!!! Digits only.</span>"
            else if (digitCount != 10)
                msg = "<span class='warning' style='color: red;'>No!!! Just 10 digits</span>"
            else msg = "<span class='valid' style='color: green;'>Done!"
            document.getElementById("mobile-status").innerHTML = msg
            return res && digitCount == 10
        },
    },

    computed: {
        ...mapState("auth", {
            user: "user",
        }),
        ...mapGetters("auth", {
            isMaterialTester: "isMaterialTester",
        }),
        forUserId() {
            return this.enquiredetails && this.enquiredetails.user_id === this.user.id
                ? this.enquiredetails.auth_id
                : this.enquiredetails.user_id
        },
        isSeller() {
            return (
                !this.isMaterialTester &&
                this.enquiredetails &&
                this.enquiredetails.user_id === this.user.id
            )
        },
    },

    async mounted() {
        scrollTop()

        setInterval(() => this.fetchMessages(), 5000)
        this.isLoading = true
        request.get("/product-count").then((res) => {
            this.product = res.data.product_count
            this.enquire_count = res.data.enquire_count
            this.jobs_count = res.data.jobs_count
            console.log(this.product)
        })

        request.get("/selectlans").then((res) => {
            this.plans = res.data
        })

        request.get("/enquiredetails").then((res) => {
            this.enquire = res.data
        })

        request.get("/singleenquiredetails/" + this.id).then(async (res) => {
            //   this.enquiredetails = res.data;
            this.enquiredetails = res.data.enquire
            this.enquire_receiver_id = res.data.receiver_id
            await this.fetchUserDetails(this.enquiredetails.user_id)
            this.isLoading = false
        })

        this.fetchMessages()
    },
}
</script>

<style lang="scss" scoped>
// a:hover {
//     color: black !important;
// }
</style>
<style lang="scss" scoped>
.imagePreview {
    width: 100%;
    height: 180px;
    background-position: center center;
    background: url(http://cliquecities.com/assets/no-image-e3699ae23f866f6cbdf8ba2443ee5c4e.jpg);
    background-color: #fff;
    background-size: cover;
    background-repeat: no-repeat;
    display: inline-block;
    box-shadow: 0px -3px 6px 2px rgba(0, 0, 0, 0.2);
}

.imgUp {
    margin-bottom: 15px;
}

.enquire {
    padding: 13px 30px !important;
    background-color: #ffff !important;
    border-radius: 0px !important;
    color: #007bff !important;
    display: inline-block !important;
}

.main-message-module {
    background: #efefef;
    padding: 10px;
}
.messages-container {
    display: flex;
    flex-direction: column;
    padding: 15px 0;
}
.message-textarea {
    font-size: 0.8em;
    box-shadow: none !important;
    outline: none !important;
    transition: 0.3s;

    &:focus {
        background: rgb(231, 255, 242);
    }
}
.send,
.recevier {
    padding: 5px 10px !important;
    margin: 5px;
    border-radius: 10px;
    font-size: 0.8em;
    min-width: 100px;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
}
.send {
    text-align: right;
    align-self: flex-end;
    background: rgb(229, 252, 229);
}
.recevier {
    background: #ddd;
    text-align: left;
    align-self: flex-start;
}
.send-message-btn {
    border-radius: 10px;
    display: inline;
    font-size: 0.8em;
    padding: 5px 10px;
    min-width: 0;
    outline: none !important;
}
</style>
